<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <b-form-select
                id="currency"
                v-model="type"
                :options="typeOptions"
                required
                @change="onTypeChange"
              ></b-form-select>
            </div>
            <div class="col-md-6 col-lg-4">
              <b-form-input type="search" placeholder="Type to search" @input="asyncFind" />
              <small v-if="isLoadingSearch"> <em> typing ... </em> </small>
            </div>
            <div class="col">
              <router-link
                class="btn btn-primary"
                :to="{ path: 'organitation/create' }"
              >
                <i class="fa fa-plus"></i>
                Add Organization
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <button class="btn btn-sm btn-dark" @click="getOrganization">
              <i class="fa fa-refresh"></i>
            </button>
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ totalRows }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="organization"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            hover
            responsive="xl"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(name)="row">
              <div v-if="row.item.type == 'vendor'">
                <router-link
                  :to="{ path: '/organitation/edit/' + row.item.id }"
                >
                  {{ row.item.name }}
                </router-link>
              </div>
              <div v-else>
                {{ row.item.name }}
              </div>
            </template>
            <template #cell(status)="row">
              <div v-if="row.item.status == 'banned'">
                <span class="badge badge-pill badge-danger">{{
                  row.item.status
                }}</span>
              </div>
              <div v-else-if="row.item.status == 'approved'">
                <span class="badge badge-pill badge-info">{{
                  row.item.status
                }}</span>
              </div>
              <div v-else-if="row.item.status == 'clear'">
                <span class="badge badge-pill badge-secondary">{{
                  row.item.status
                }}</span>
              </div>
              <div v-else>
                <span class="badge badge-pill badge-primary">{{
                  row.item.status
                }}</span>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'Organization',
  data() {
    return {
      filter: {},
      items_search: [],
      currentPage: 1,
      perPage: 20,
      isLoadingSearch: false,
      debounce: null,
      fields: [
        { key: 'No', label: 'No.', thStyle: { maxWidth: '15px' } },
        { key: 'name', label: 'Name' },
        { key: 'type', label: 'Type' },
        { key: 'status', label: 'Status' },
        {
          key: 'created',
          label: 'Join Date',
          formatter: function(value) {
            return value != null ? moment(value).format('DD-MM-YYYY') : '-';
          },
        },
      ],
      type: 'all-type',
      typeOptions: [
        { value: 'all-type', text: 'All Type' },
        { value: 'vendor', text: 'vendor' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.getOrganization();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Organization | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.organization.isLoading,
      isError: (state) => state.organization.isError,
      totalRows: (state) => state.organization.totalRows,
      organization: (state) => state.organization.items,
    }),
  },
  mounted() {
    this.getOrganization();
  },
  methods: {
    ...mapActions('organization', [
      'fetchOrganization',
      'findOrganization',
      'fetchOrganizationById',
    ]),

    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return 'find organization...';
      }
    },

    getOrganization() {
      let payload = {
        type: this.type,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchOrganization(payload);
    },

    asyncFind(query) {
      if (!query) {
        this.getOrganization();
        return;
      }

      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          page: this.currentPage,
          limit: this.perPage,
        };

        this.findOrganization(payload)
          .then((response) => {
            this.items_search = response.data.data.rows;
            this.isLoadingSearch = false;
          })
          .catch(() => {
            this.isLoadingSearch = false;
          });
      }, 600);
    },

    actionFindOne(query) {
      this.fetchOrganizationById(query);
    },

    onTypeChange() {
      let payload = {
        type: this.type,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchOrganization(payload);
    },
  },
};
</script>
